/* eslint-disable react-hooks/exhaustive-deps */
import { FeatureTypes } from '@eagle/data-function-types';
import { Cancel } from '@mui/icons-material';
import { Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FILTER_SELECT_ALL_FLAG } from '../../util';
import { EntityField } from '../entity-search';
import { useBoolFlag } from '../flags';
import { SelectionCheckBox, SelectionCheckBoxGroup } from '../selection-check-box';

const MAX_NUMBER_OF_CHIPS = 4;

interface Props {
  appliedFilters: EntityField[];
  label?: string;
  onChanged?: (value: EntityField[]) => void;
  values?: EntityField[];
}

interface SelectItem {
  eventKey: string;
  display: string;
  feature: string;
}

interface AlertFieldValue {
  events: string[];
  feature: string;
  featureType: string;
}

type SelectedValueType = Record<string, AlertFieldValue>

export const FilterEventTypeField: FC<Props> = ({ appliedFilters, label, onChanged, values = [] }) => {
  const { t, i18n } = useTranslation(['common']);
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);
  const [checkedFilters, setCheckedFilters] = useState<EntityField[]>(appliedFilters);

  const getEventLabel = (featureTypeId: string, event: string): string => {
    const key = `common:event-descriptions-v2.${featureTypeId}.${event}.label`;
    if (i18n.exists(key)) {
      return t(key);
    }
    return event;
  };

  const getAlertFieldValues = useCallback((items: EntityField[]) => {
    return items.reduce((acc, item) => {
      if (!item.alertProperties) return acc;
      const { feature, featureType, alert } = item.alertProperties;
      const existing = acc.find((item2) => item2.feature === feature);

      if (!existing) {
        acc.push({ feature, featureType, events: [alert] });
        return acc;
      }

      existing.events.push(alert);
      return acc;
    }, [] as AlertFieldValue[]);
  }, [values]);

  const selectedValue = useMemo(() => {
    return getAlertFieldValues(checkedFilters).reduce((accumulator, current) => {
      return {
        ...accumulator,
        [current.feature]: {
          events: current.events,
          feature: current.feature,
          featureType: current.featureType,
        },
      };
    }, {} as SelectedValueType);
  }, [checkedFilters]);

  const formatSelectedValue = useMemo(() => {
    return Object.values(selectedValue).flatMap((value) => {
      return value.events.map((eventKey) => ({
        eventKey,
        display: value.featureType === FeatureTypes.EVENT_RECORD_V0
          ? `${t(`common:features.${value.feature}`)} – ${t(`common:event-descriptions-v2.${value.featureType}.${eventKey}.label`)}`
          : `${t(`common:event-descriptions-v2.${value.featureType}.${eventKey}.label`)} – ${t(`common:features.${value.feature}`)}`,
        feature: value.feature,
      }));
    });
  }, [selectedValue]);

  const updateSelectedValue = (key: string, featureType: string) => (value: string[]): void => {
    const newFields = value.map((eventKey) => {
      return {
        id: `${eventKey}`,
        display: eventKey,
        alertProperties: {
          feature: key,
          featureType,
          alert: eventKey,
        },
      };
    });

    const otherFilters = checkedFilters.filter((item) => {
      return item.alertProperties?.feature !== key;
    });

    if (value.length === 0) {
      return setCheckedFilters(otherFilters);
    }
    setCheckedFilters([
      ...newFields,
      ...otherFilters,
    ]);
  };

  const alertFieldValues = getAlertFieldValues(values);
  const numberOfOptions = alertFieldValues.reduce((result, option) => result + option.events.length, 0);
  const isAllSelected = formatSelectedValue.length === numberOfOptions;

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      return setCheckedFilters([]);
    }
    const allValues = alertFieldValues.flatMap((value) => value.events.map((eventKey) => ({
      id: `${value.feature}-${eventKey}`,
      display: eventKey,
      alertProperties: {
        feature: value.feature,
        featureType: value.featureType,
        alert: eventKey,
      },
    })));
    setCheckedFilters(allValues);
  };

  useEffect(() => {
    onChanged?.(checkedFilters);
  }, [checkedFilters]);

  return (
    <FormControl size="small">
      <InputLabel id="filter-label">{label}</InputLabel>
      <Select<SelectItem[]>
        label={label}
        MenuProps={{
          sx: {
            maxWidth: 250,
            maxHeight: 400,
          },
        }}
        multiple
        renderValue={(selected) => (
          <Stack spacing={1} sx={{ alignItems: 'flex-start' }}>
            {selected.slice(0, MAX_NUMBER_OF_CHIPS).map((value, i) => (
              <Chip
                key={i}
                label={value.display}
                onDelete={() => {
                  setCheckedFilters((selected) => {
                    const updatedFilters = selected.filter((item) => {
                      return item.alertProperties?.feature !== value.feature || item.alertProperties?.alert !== value.eventKey;
                    });
                    return updatedFilters;
                  });
                }}
                deleteIcon={<Cancel onMouseDown={(e) => e.stopPropagation()} />}
                sx={{ maxWidth: 1 }}
                size="small"
              />
            ))}
            {selected.length > MAX_NUMBER_OF_CHIPS && <Typography component="span" variant="body2">+{selected.length - MAX_NUMBER_OF_CHIPS}</Typography>}
          </Stack>
        )}
        size="small"
        value={formatSelectedValue}
      >
        {hasFilterSelectAllFlag && (
          <MenuItem divider sx={{ p: 0 }}>
            <SelectionCheckBox
              checked={isAllSelected}
              handleClick={toggleSelectAll}
              label={<strong>{t('common:component.filter-dropdown.labels.select-all')}</strong>}
            />
          </MenuItem>
        )}
        {alertFieldValues?.map((item, i) => (
          <SelectionCheckBoxGroup
            key={i}
            groupDisplay={t(`common:features.${item.feature}`)}
            itemsChecked={selectedValue[item.feature]?.events ?? []}
            subItemDisplay={(subItem) => getEventLabel(item.featureType, subItem)}
            subItems={item.events}
            updateSelectedValue={updateSelectedValue(item.feature, item.featureType)}
          />
        ))}
      </Select>
    </FormControl>
  );
};
