import { DateTime } from 'luxon';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useBoolFlag } from '../flags';
import { InfoIcon } from '../icons';
import { MenuItem } from './menu-item';

interface Props {
  open?: boolean;
}

export const MenuFooter: FC<Props> = ({ open }) => {
  const { t } = useTranslation(['common']);
  const displayPortalVersion = useBoolFlag('portals-display-portal-version-in-sidebar');
  const build = import.meta.env.VITE_BUILD || import.meta.env.REACT_APP_BUILD; // TODO: DEV-371

  const getSubText = (): string => {
    const buildTimestamp = import.meta.env.VITE_BUILD_DATE || import.meta.env.REACT_APP_BUILD_DATE; // TODO: DEV-371
    if (!buildTimestamp) return t('common:component.navigation.update.labels.up-to-date');

    const buildDate = DateTime.fromISO(buildTimestamp);
    if (!buildDate.isValid) return t('common:component.navigation.update.labels.up-to-date');
    return buildDate.toLocal().toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS);
  };
  if (!displayPortalVersion) return <></>;
  return <MenuItem
    icon={<InfoIcon />}
    open={open}
    sx={{ '& .icon': { minWidth: 72, width: 72 }, cursor: 'default' }}
    subText={getSubText()}
    text={t('common:common.labels.version', { build: build ?? t('common:component.navigation.update.labels.manualBuild') })}
  />;
};
