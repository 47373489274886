/* eslint-disable react-hooks/rules-of-hooks */
import { Change } from '@eagle/api-types';
import { Button, Typography } from '@mui/material';
import { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Alert, BottomNavigation, BottomNavigationAction, LoadingButton, MiddleSpinner } from '../../components';
import { ErrorMessage, ErrorTitle } from '../../components/error-message';
import { FlexBox } from '../../components/flex-box';
import { useSmallScreen } from '../../hooks/use-small-screen';
import { useTitle } from '../../hooks/use-title';
import { Nullable } from '../../types';
import { testid } from '../../util/test-id';
import { NotFound } from '../errors';
import LayoutDesktop from './layout-desktop';
import LayoutMobile from './layout-mobile';
import { ViewProps } from './view.props';

interface DataTyped {
  deleted?: Nullable<Change>;
  finish?: Nullable<Change>;
}

/**
  * NATIVE FUNCTION: View component of detail page
  */
// eslint-disable-next-line prefer-arrow/prefer-arrow-functions
export function DetailView<T>({
  actions,
  breadcrumbs,
  data: [data, error, state],
  disabled,
  pageAlert,
  pageIcon,
  renderDeletedBannerContent,
  renderDisplay,
  renderPageContent,
  renderPageTitle,
  ...props
}: ViewProps<T>): JSX.Element {
  const { t } = useTranslation(['common']);
  const smallScreen = useSmallScreen();

  const pageActions = (): JSX.Element => {
    const computedActions = typeof actions === 'function' ? data === undefined ? [] : actions(data) : actions;

    if (computedActions.length < 1) return <></>;

    if (!smallScreen) {
      return <>
        {computedActions.map((action, i) => {
          return action.onPromiseClick
            ? <LoadingButton
              key={i}
              data-testid={testid`action-button-${action.label}`}
              disabled={disabled || action.disabled}
              href={action.href}
              startIcon={action.icon}
              sx={{ minHeight: '36px', minWidth: '168px', ...action.sx }}
              task={action.onPromiseClick}
              variant="text"
            >
              {action.label}
            </LoadingButton>
            : action.buttonNode
              ? <Fragment key={i}>{action.buttonNode}</Fragment>
              : <Button
                key={i}
                data-testid={testid`action-button-${action.label}`}
                disabled={disabled || action.disabled}
                href={action.href}
                onClick={action.onClick}
                startIcon={action.icon}
                sx={action.sx}
              >
                {action.label}
              </Button>;
        })}
      </>;
    }
    return (
      <BottomNavigation>
        {computedActions.map((action, i) => {
          if (action.buttonNode) {
            return <Fragment key={i}>{action.buttonNode}</Fragment>;
          }
          return (
            <BottomNavigationAction
              key={i}
              data-testid={testid`action-button-${action.label}`}
              disabled={disabled || action.disabled}
              href={action.href}
              icon={action.icon}
              label={action.label}
              onClick={action?.onPromiseClick ?? action.onClick}
            />
          );
        })}
      </BottomNavigation>
    );
  };

  if (state === 'pending') {
    useTitle(t('common:common.labels.loading'));
    return (
      <FlexBox sx={{ height: '200px', justifyContent: 'center', width: '100vw' }}>
        <MiddleSpinner />
      </FlexBox>
    );
  }

  if (error) {
    useTitle(<ErrorTitle error={error} />);
    return (
      <Alert severity="error" sx={{ margin: '1rem' }}>
        <ErrorMessage error={error} />
      </Alert>
    );
  }

  if (data === undefined) {
    useTitle(t('common:page.errors.not-found.title'));
    return (
      <FlexBox>
        <NotFound />
      </FlexBox>
    );
  }

  useTitle(renderPageTitle(data));

  const dataTyped = data as DataTyped;
  const deletedBanner = dataTyped?.deleted || dataTyped?.finish
    ? <Alert severity="info" sx={{ width: '100%' }} data-testid="deleted-alert-banner">
      <Typography>
        {renderDeletedBannerContent ? renderDeletedBannerContent(data) : (
          <Trans
            components={[<b key={renderDisplay?.(data)} />, <i key="read-only" />]}
            i18nKey="common:page.details.deleted.hint"
            values={{ display: renderDisplay?.(data) }}
          />
        )}
      </Typography>
    </Alert>
    : undefined;

  if (smallScreen) {
    return (
      <LayoutMobile
        actions={pageActions()}
        deletedBanner={deletedBanner}
        data-testid={props['data-testid']}
      >
        {pageAlert}
        {renderPageContent(data)}
      </LayoutMobile>
    );
  }

  return (
    <LayoutDesktop
      actions={pageActions()}
      data-testid={props['data-testid']}
      deletedBanner={deletedBanner}
      pageAlert={pageAlert}
      pageIcon={pageIcon}
      pageSubtitle={breadcrumbs}
      pageTitle={renderPageTitle(data)}
    >
      {renderPageContent(data)}
    </LayoutDesktop>
  );
}
