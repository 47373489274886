/* eslint-disable react-hooks/exhaustive-deps */
import { TypeDefinitionTypes } from '@eagle/common';
import { FeatureTypes } from '@eagle/data-function-types';
import { Chip, useTheme } from '@mui/material';
import { SxProps } from '@mui/system';
import { FC, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { testid } from '../../util/test-id';
import { AppliedFilter, EntityField } from '../entity-search/types';

interface Props {
  disabled?: boolean;
  filter: AppliedFilter;
  onRemoveClicked: () => unknown;
  sx?: SxProps;
}

export const FilterElement: FC<Props> = ({ disabled, filter, onRemoveClicked, sx }) => {
  const { t } = useTranslation(['common']);
  const chipRef = useRef(null);
  const theme = useTheme();

  const formatValue = (): string => {
    if ((filter.value as EntityField).alertProperties) {
      const properties = (filter.value as EntityField).alertProperties;
      const featureDisplay = t(`common:features.${properties?.feature as string}`);
      const alertDisplay = t(`common:alert-descriptions.${properties?.featureType as string}.${properties?.alert as string}.label`);
      return properties?.feature === FeatureTypes.EVENT_RECORD_V0 ? `${featureDisplay} - ${alertDisplay}` : `${alertDisplay} - ${featureDisplay}`;
    }
    if (filter.definition.type === 'entity' || filter.definition.type === TypeDefinitionTypes.REFERENCE) {
      return t('common:component.filter.labels.is', {
        field: filter.definition.label,
        value: (filter.value as EntityField).display,
      });
    }
    return t('common:component.filter.labels.is', {
      field: filter.definition.label,
      value: filter.value as string,
    });
  };

  const formatTestId = (): string => {
    let value: string;
    if (filter.definition.type === 'entity' || filter.definition.type === TypeDefinitionTypes.REFERENCE) {
      value = (filter.value as EntityField).display;
    } else {
      value = filter.value.toString();
    }
    return (testid`filter-${filter.definition.label}-${value}`);
  };

  const keyDownHandler = (event: KeyboardEvent): void => {
    if (event.key === ' ' && document.activeElement === chipRef.current) {
      onRemoveClicked();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', keyDownHandler);
    return () => {
      window.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  return (
    <Chip
      data-testid={formatTestId()}
      ref={chipRef}
      disabled={disabled}
      label={formatValue()}
      onDelete={onRemoveClicked}
      sx={{
        margin: '0 0.5rem 0.7rem 0',
        '& .MuiChip-deleteIcon': {
          color: theme.iconColors?.delete ?? undefined,
        },
        ...sx,
      }}
    />
  );
};
