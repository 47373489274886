import { Thing, ThingType } from '@eagle/core-data-types';
import { FeatureTypes } from '@eagle/data-function-types';
import { Box, Divider, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CacheDataTypes } from '../../../types/cache';
import { kphToMph } from '../../../util';
import { getEventDictionary } from '../../entity-journey/util';
import { EventAddress } from '../../events/event-address';
import { FetchOne, FetchOneOfAll } from '../../fetch';
import { FormatTimestamp } from '../../format';
import { LabelledBadge } from '../../labelled-badge';
import { EventLocationData } from './thing-event-pane.types';

interface Props {
  data: EventLocationData[];
  dataLoaded?: () => void;
}

const POPUP_LIMIT = 3;

export const EventBreadcrumbPopover: FC<Props> = ({ data: input, dataLoaded }) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();

  const filteredInput = useMemo(() => input.filter(({ data }) => !!data.location), [input]);

  const eventStylesMemoized = useMemo(() => {
    const eventStyles = filteredInput.map((eventSnapshot) => getEventDictionary(eventSnapshot, theme));
    return eventStyles;
  }, [filteredInput, theme]);

  if (!filteredInput.length) return <></>;

  return (
    <>
      {filteredInput.slice(0, POPUP_LIMIT + 1).map((eventSnapshot, i) => {
        const { data, eventTypeId, feature, featureTypeId, occurred, thingId } = eventSnapshot;
        const speed = data.velocity?.speed ?? 0;
        const eventStyles = eventStylesMemoized[i];

        if (i === POPUP_LIMIT) {
          return (
            <Box key={i} sx={{ pt: 2 }}>
              <Typography>{t('common:component.map.labels.more-items', { count: filteredInput.length - i })}</Typography>
            </Box>
          );
        }
        const featureLabel = t(`common:features.${feature}`);
        const eventTypeLabel = t(`common:event-descriptions-v2.${featureTypeId}.${eventTypeId}.label`);
        const primary = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? featureLabel : eventTypeLabel;
        const secondary = featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? eventTypeLabel : featureLabel;

        return (
          <Stack key={i} sx={{ mt: 1 }}>
            {i !== 0 && <Divider />}
            <FetchOne
              id={thingId}
              dataType={CacheDataTypes.THING}
              renderFactory={(item: Thing) => (
                <FetchOneOfAll
                  id={item.thingTypeId}
                  dataType={CacheDataTypes.THING_TYPE}
                  renderFactory={(thingType: ThingType) => (
                    <Stack direction="row" justifyContent="space-between" sx={{ mb: 1 }}>
                      <Box>
                        <Typography sx={{ fontWeight: 'bold' }}>{item.display}</Typography>
                        <Typography variant="body1">{thingType.display}</Typography>
                      </Box>
                      <Stack direction="row" alignItems="center">
                        <LabelledBadge
                          align="left"
                          background={eventStyles?.styling.backgroundColor}
                          color={eventStyles?.styling.textColor}
                          label={primary}
                          subtitle={secondary}
                        />
                      </Stack>
                    </Stack>
                  )}
                />
              )}
            />
            <Stack direction="row" spacing={1}>
              <Typography sx={{ fontWeight: 'bold' }}>{t('common:terms.location')}</Typography>
              <EventAddress
                addressUnavailableText={t('common:component.map.hint.no-location')}
                dataLoaded={dataLoaded}
                loadingComponent={<Skeleton height={'20px'} width={'100%'} />}
                location={data.location}
                variant="body1"
              />
            </Stack>
            {occurred && (
              <Stack direction="row" spacing={1}>
                <Typography sx={{ fontWeight: 'bold' }}>{t('common:component.map.labels.occurred-at')}</Typography>
                <Typography>
                  <FormatTimestamp value={occurred} />
                </Typography>
              </Stack>
            )}
            {!isNaN(speed) && (
              <Stack direction="row" spacing={1}>
                <Typography sx={{ fontWeight: 'bold' }}>{t('common:component.events.labels.speed')}</Typography>
                <Typography>{t('common:component.events.labels.speed-value', { kph: speed, mph: kphToMph(speed) })}</Typography>
              </Stack>
            )}
          </Stack>
        );
      })}
    </>
  );
};
