/* eslint-disable react-hooks/exhaustive-deps */
import { useTheme } from '@mui/material';
import { useEffect } from 'react';
import { AuthStatus, useAuth } from '../../auth';
import { useBoolFlag } from '../../components';
import { useConfig, useSwitchAwareConfig } from '../use-config';

const createIntercomTrackingCode = (appId: string): string => {
  return `(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/' + '${appId}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s, x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`;
};

export const useIntercomGuide = (): void => {
  const { intercom: intercomConfig } = useConfig();
  const { productName } = useSwitchAwareConfig();
  const theme = useTheme();

  const { state } = useAuth();
  const hasIntercomFlag = useBoolFlag('portals-intercom-guide-feature');

  useEffect(() => {
    if (!state || state.status !== AuthStatus.AUTHENTICATED) {
      return;
    }

    if (!intercomConfig?.appId || !intercomConfig?.apiBase || !hasIntercomFlag) return;

    const { account, user } = state;

    const scriptElement = document.createElement('script');
    scriptElement.innerHTML = createIntercomTrackingCode(intercomConfig.appId);
    document.head.appendChild(scriptElement);

    window.Intercom('boot', {
      'app_id': intercomConfig.appId,
      'api_base': intercomConfig.apiBase,
      email: user.email || '',
      name: user.display,
      'user_id': user._id,
      'background_color': theme.palette.primary.main,
      'action_color': theme.palette.primary.main,
      company: {
        'company_id': account._id,
        name: account.display,
        tags: {
          type: 'tag.list',
          tags: account.tags,
        },
      },
      'home_domain': account.homeDomain,
      'product': productName,
    });

    return () => {
      window.Intercom('shutdown');
      if (scriptElement) { document.head.removeChild(scriptElement); }
    };
  }, [productName, state, intercomConfig]);
};
