import { AlertResponse } from '@eagle/core-data-types';
import { FeatureTypes } from '@eagle/data-function-types';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import { useTheme } from '@mui/material';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { getAlertTypeStyle } from '../../util';
import { LabelledBadge } from '../labelled-badge';

interface AlertLabelProps {
  align?: 'left' | 'right' | 'center';
  alert: AlertResponse;
}

export const AlertLabel: FC<AlertLabelProps> = ({ alert, align = 'left' }) => {
  const theme = useTheme();
  const alertStyles = getAlertTypeStyle(alert, theme);
  const { t } = useTranslation(['common']);
  const suppressionIcon = alert.suppressed ? <NotificationsOffIcon /> : undefined;
  const suppressionLabel = alert.suppressed ? t('common:component.alerts-table.hint.suppression') : undefined;
  const featureLabel = t(`common:features.${alert.feature}`);
  const alertTypeLabel = t(`common:alert-descriptions.${alert.featureTypeId}.${alert.alertTypeId}.label`);
  const label = alert.featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? featureLabel : alertTypeLabel;
  const subtitle = alert.featureTypeId === FeatureTypes.EVENT_RECORD_V0 ? alertTypeLabel : featureLabel;

  return (
    <LabelledBadge
      align={align}
      background={alertStyles.background}
      color={alertStyles.color}
      fullWidth
      icon={suppressionIcon}
      label={label}
      subtitle={subtitle}
      tooltip={suppressionLabel}
    />
  );
};
