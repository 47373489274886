/* eslint-disable react-hooks/exhaustive-deps */
import { FeatureTypes } from '@eagle/data-function-types';
import { Chip, FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { Box } from '@mui/system';
import { FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { FILTER_SELECT_ALL_FLAG } from '../../util';
import { EntityField } from '../entity-search';
import { useBoolFlag } from '../flags';
import { SelectionCheckBox, SelectionCheckBoxGroup } from '../selection-check-box';

interface Props {
  appliedFilters: EntityField[];
  label?: string;
  onChanged?: (value: EntityField[]) => void;
  values?: EntityField[];
}

interface SelectItem {
  alert: string;
  display: string;
  feature: string;
}

interface AlertFieldValue {
  alerts: string[];
  feature: string;
  featureType: string;
}

type SelectedValueType = Record<string, AlertFieldValue>

export const FilterAlertTypeField: FC<Props> = ({ appliedFilters, label, onChanged, values = [] }) => {
  const { t } = useTranslation(['common']);
  const hasFilterSelectAllFlag = useBoolFlag(FILTER_SELECT_ALL_FLAG);

  const getAlertFieldValues = useCallback((items: EntityField[]) => {
    return items.reduce((acc, item) => {
      if (!item.alertProperties) return acc;
      const { feature, featureType, alert } = item.alertProperties;
      const existing = acc.find((item2) => item2.feature === feature);

      if (!existing) {
        acc.push({ feature, featureType, alerts: [alert] });
        return acc;
      }

      existing.alerts.push(alert);
      return acc;
    }, [] as AlertFieldValue[]);
  }, [values]);

  const selectedValue = useMemo(() => {
    return getAlertFieldValues(appliedFilters).reduce((accumulator, current) => {
      return {
        ...accumulator,
        [current.feature]: {
          alerts: current.alerts,
          feature: current.feature,
          featureType: current.featureType,
        },
      };
    }, {} as SelectedValueType);
  }, [appliedFilters]);

  const formatSelectedValue = useMemo(() => {
    return Object.values(selectedValue).flatMap((value) => {
      return value.alerts.map((alert) => {
        const featureDisplay = t(`common:features.${value.featureType}`);
        const alertDisplay = t(`common:alert-descriptions.${value.featureType}.${alert}.label`);

        return {
          alert,
          display: value.featureType === FeatureTypes.EVENT_RECORD_V0 ? `${featureDisplay} - ${alertDisplay}` : `${alertDisplay} - ${featureDisplay}`,
          feature: value.feature,
        };
      });
    });
  }, [selectedValue]);

  const handleDelete = (value: SelectItem) => (): void => {
    const updatedFilters = appliedFilters.filter((item) => {
      return item.alertProperties?.feature !== value.feature
        || item.alertProperties?.alert !== value.alert;
    });
    onChanged?.(updatedFilters);
  };

  const updateSelectedValue = (key: string, featureType: string) => (value: string[]): void => {
    const newFields = value.map((alert) => {
      return {
        id: `${key}-${alert}`,
        display: alert,
        alertProperties: {
          feature: key,
          featureType,
          alert,
        },
      };
    });

    const otherFilters = appliedFilters.filter((item) => {
      return item.alertProperties?.feature !== key;
    });

    if (value.length === 0) {
      return onChanged?.(otherFilters);
    }

    onChanged?.([
      ...newFields,
      ...otherFilters,
    ]);
  };

  const alertFieldValues = getAlertFieldValues(values);
  const numberOfOptions = alertFieldValues.reduce((result, option) => result + option.alerts.length, 0);
  const isAllSelected = formatSelectedValue.length === numberOfOptions;

  const toggleSelectAll = (): void => {
    if (isAllSelected) {
      return onChanged?.([]);
    }
    const allValues = alertFieldValues.flatMap((value) => value.alerts.map((alert) => ({
      id: `${value.feature}-${alert}`,
      display: alert,
      alertProperties: {
        feature: value.feature,
        featureType: value.featureType,
        alert,
      },
    })));
    onChanged?.(allValues);
  };

  return (
    <FormControl data-testid="alert-type-select" size="small" >
      <InputLabel id="filter-label">{label}</InputLabel>
      <Select<SelectItem[]>
        label={label}
        MenuProps={{
          sx: {
            maxWidth: 250,
            maxHeight: 400,
          },
        }}
        multiple
        renderValue={(selected) => (
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
            {selected.map((value) => (
              <Chip
                key={value.display}
                label={value.display}
                onDelete={handleDelete(value)}
                onMouseDown={(event) => event.stopPropagation()}
                size="small"
              />
            ))}
          </Box>
        )}
        size="small"
        value={formatSelectedValue}
      >
        {hasFilterSelectAllFlag && (
          <MenuItem divider sx={{ p: 0 }}>
            <SelectionCheckBox
              checked={isAllSelected}
              handleClick={toggleSelectAll}
              label={<strong>{t('common:component.filter-dropdown.labels.select-all')}</strong>}
            />
          </MenuItem>
        )}
        {alertFieldValues?.map((item, i) => (
          <SelectionCheckBoxGroup
            key={i}
            groupDisplay={t(`common:features.${item.feature}`)}
            itemsChecked={selectedValue[item.feature]?.alerts ?? []}
            subItemDisplay={(subItem: string) => t(`common:alert-descriptions.${item.featureType}.${subItem}.label`)}
            subItems={item.alerts}
            updateSelectedValue={updateSelectedValue(item.feature, item.featureType)}
          />
        ))}
      </Select>
    </FormControl>
  );
};
